import React from 'react'

function Spinner({color="white"}) {
    return (
        <div class={`spinner-border text-${color}`} style={{'width':'7rem', 'height': '7rem'}} role="status">
            <span class="visually-hidden">Loading...</span>
        </div>

    )
}

export default Spinner