import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuill } from "react-quilljs";
import Inscripcion from "../../components/inscripcion/Inscripcion";
import "quill/dist/quill.snow.css";
import "./detalleTrabajo.css";
import Spinner from "../../components/spinner/Spinner";

const DetalleTrabajo = () => {
  const { id } = useParams();
  const [trabajo, setTrabajo] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const { quill, quillRef } = useQuill({
    readOnly: true,
    modules: {
      toolbar: false,
    },
  });

  useEffect(() => {
    const fetching = async () => {
      try {
        const api = `https://backend-rcyasociados-vercel-n4a1.vercel.app/api/trabajos/${id}`;
        const res = await fetch(api);
        const trabajo = await res.json();
        setTrabajo(trabajo);
        quill.setContents(JSON.parse(trabajo.descripcion));
      } catch (error) {
        console.log("Error: ", error)
      } finally {
        setIsLoading(false)
      }

    };

    if (quill) {
      fetching();
    }
  }, [quill]);
  return (
    <div className="">
      <div className="bg-white mt-4 rounded-1 p-3 mx-5">
        {isLoading ? <section className="d-flex justify-content-center my-4"> <Spinner color="dark" /> </section> : ''}
        <h2 className="text-center fw-bold fs-1 text-uppercase m-0">
          {trabajo.titulo}
        </h2>
        <p className="text-center text-capitalize text-muted">
          <i className="fa-solid fa-location-dot me-1"></i>
          {trabajo.ubicacion}
        </p>
        <div>
          <div ref={quillRef} className="border-0"></div>
        </div>
        <button
          className="btn btn-success mt-3"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Postularme
        </button>

        <Inscripcion />

      </div>
    </div>
  );
};

export default DetalleTrabajo;
