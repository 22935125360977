import React from "react"
import './App.css';
import Rutas from "./components/rutas/Rutas"
import Footer from "./components/footer/Footer"

function App() {
  return (
    <>
      <Rutas/>
      <Footer />
    </>
  );
}

export default App;
