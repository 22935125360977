import React, { useState, useEffect } from 'react'
import Header from "../../components/header/Header";
import Cards from "../../components/cards/Cards"
import useFecthData from '../../hooks/useFecthData';
import Spinner from '../../components/spinner/Spinner';

const Trabajos = () => {
  const { trabajo, isLoading } = useFecthData({ category: "trabajo" })

  return (
    <>
      <Header className="headerTrabajos" texto="Encontra con nosotros tu proximo empleo" />
      
      {
        isLoading ? <section className='d-flex justify-content-center my-4'><Spinner/></section> :
          <Cards datos={trabajo} />
      }
      
    </>
  )
}

export default Trabajos