import { useState, useEffect } from "react"
import { API_CURSOS, API_NOTICIAS, API_TRABAJOS } from "../constants/constants"

const useFecthData = ({ category, unique = false, id, limit = false, all = false }) => {
    const [trabajo, setTrabajo] = useState([])
    const [curso, setCurso] = useState([])
    const [noticia, setNoticia] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const getTrabajos = async () => {
        const API_URL = API_TRABAJOS
        const callData = await fetch(API_URL)
        let newData = await callData.json()
        newData.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });
        if (limit) {
            newData = newData.slice(0, 3)
        }
        setTrabajo(newData)
        setIsLoading(false)
    }

    const getCursos = async () => {
        const API_URL = API_CURSOS
        const callData = await fetch(API_URL)
        let newData = await callData.json()
        newData.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });
        if (limit) {
            newData = newData.slice(0, 3)
        }
        setCurso(newData)
        setIsLoading(false)
    }

    const getNoticias = async () => {
        const API_URL = API_NOTICIAS
        const callData = await fetch(API_URL)
        let newData = await callData.json()
        if (limit) {
            newData = newData.slice(0, 3)
        }
        setNoticia(newData)
        setIsLoading(false)
    }

    useEffect(() => {
        if (all === true) {
            getTrabajos()
            getCursos()
        }
        if (category === 'noticia') {
            getNoticias()
        } else if (category === 'curso') {
            getCursos()
        } else if (category === 'trabajo') {
            getTrabajos()
        }
    }, [])


    return {
        trabajo,
        noticia,
        curso,
        isLoading
    }
}


export default useFecthData