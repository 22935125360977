import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import Inscripcion from "../../components/inscripcion/Inscripcion";
import Spinner from "../../components/spinner/Spinner";

const DetalleCurso = () => {
  const { id } = useParams();
  const [curso, setCurso] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const { quill, quillRef } = useQuill({
    readOnly: true,
    modules: {
      toolbar: false,
    },
  });
  useEffect(() => {
    const fetching = async () => {
      try {
        const api = `https://backend-rcyasociados-vercel-n4a1.vercel.app/api/cursos/${id}`;
        const res = await fetch(api);
        const curso = await res.json();
        setCurso(curso);
        quill.setContents(JSON.parse(curso.descripcion));
      } catch (error) {
        console.error("Error: ", error)
      } finally {
        setIsLoading(false)
      }

    };
    if (quill) {
      fetching();
    }
  }, [quill, id]);
  return (
    <div className="">
      <div className="bg-white mt-4 mx-5 rounded-1 p-5">
        {isLoading ? <section className="d-flex justify-content-center my-4"> <Spinner color="dark" /> </section>: ''}
        <h2 className="text-center fw-bold fs-1 text-uppercase m-0">
          {curso.titulo}
        </h2>
        <p className="text-center text-capitalize text-muted">
          {curso.ubicacion}
        </p>
        <div>
          <div ref={quillRef} className="border-0"></div>
        </div>
        <div className="mt-2">
          <a
            className="btn btn-success"
            href={`https://api.whatsapp.com/send?phone=+543834921802&text=%C2%A1Hola,%20buenas%20tardes!%20Estoy%20interesado%20en%20el%20curso%20-${curso.titulo}-%20y%20me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20para%20poder%20inscribirme.%20%C2%BFPodr%C3%ADa%20proporcionarme%20detalles%20adicionales,%20por%20favor?`}
            target="_blank"
          >
            Inscribirme
          </a>
          <a href={curso.programa} target="_blank" className="btn btn-secondary ms-2">
            Programa
          </a>
        </div>
      </div>
    </div>
  );
};

export default DetalleCurso;
