import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import useFecthData from "../../hooks/useFecthData";
import Loader from "../loader/Loader";
import "./Carousel.css";

const CarouselCards = () => {
  const imagenDefault = "https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bWljciVDMyVCM2Zvbm98ZW58MHx8MHx8fDA%3D&w=1000&q=80"
  const { noticia, isLoading } = useFecthData({ all: false, category: 'noticia' })

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];
  return (
    <div className="">
      {
        isLoading ? <div className="d-flex justify-content-center my-3"><Loader /></div>
          : (
            <Carousel breakPoints={breakPoints}>
              {noticia.map((noticia) => (
                <div className="card mx-1 cardNoticia" key={noticia._id}>
                  <img 
                    src={noticia.img || imagenDefault} 
                    onError={(e) => {
                      e.target.src = imagenDefault;
                      e.target.onerror = null; // Evita un posible bucle en caso de que la imagen por defecto también falle
                    }}
                    width={300} 
                    height={180} />
                  <div className="card-body">
                    <h4 className="card-title text-truncate">{noticia.titulo}</h4>
                    <p className="card-text textoNoticia">{noticia.descripcion}</p>
                  </div>
                  <div className="card-footer border-0">
                    <a
                      href={`${noticia.link}`}
                      className="btn btn-dark rounded-0 form-control"
                      target="_blank"
                    >
                      Ver mas
                    </a>
                  </div>
                </div>
              ))}
            </Carousel>)
      }
    </div>
  );
};

export default CarouselCards;
