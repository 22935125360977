import React from 'react'
import swal from '@sweetalert/with-react'
import { API_NEWSLETTER_END } from '../../constants/constants'

function NewsletterEnd() {
    async function unsuscribe(e) {
        e.preventDefault()
        swal("", "Cargando... Espera porfavor")
        let data = {
            email: e.target.email.value.trim().toLowerCase()
        }

        const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (data.email === "") {
            swal("Error", "El campo 'email' no puede estar vacio", "error")
            return
        }
        if (!regexEmail.test(data.email)) {
            swal("Error", "El email ingresado no es valido", "error")
            return
        }

        try {
            const response = await fetch(API_NEWSLETTER_END, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
                },
                body: JSON.stringify(data)
            })
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message);
            }
            swal("Exito", "Te has suscrito de nuestro newsletter con exito, ya no recibiras mas mails", "success")
            e.target.email.value = ""
        } catch (error) {
            swal("Error", `Ha ocurrido un error: ${error}`, "error")
        }
    }
    return (
        <main className='container my-5 d-flex justify-content-center'>
            <section className='card w-50'>
                <form onSubmit={unsuscribe}>
                    <section className='card-header'>
                        <p className='m-0 fs-4'>Desuscribirse del newsletter</p>
                    </section>
                    <section className='card-body'>
                        <label htmlFor="email" className='form-label'>Ingresa tu email</label>
                        <input className='form-control' type='text' name='email' placeholder='tuemail@dominio.com' />
                    </section>
                    <section>
                        <button type='submit' className='btn btn-danger my-2 mx-3'>Desuscribirme</button>
                    </section>
                </form>
            </section>
        </main>
    )
}

export default NewsletterEnd