import React from 'react'
import FormularioEmpresas from '../../components/formularioEmpresas/FormularioEmpresas'

const Empresas = () => {
  return (
    <div className='d-flex justify-content-center'>
        <FormularioEmpresas />
    </div>
  )
}

export default Empresas