import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./cards.css";

const formatTimeSincePublication = (publicationDate) => {
  const now = new Date();
  const publishedDate = new Date(publicationDate);
  const timeDifference = now - publishedDate;

  const seconds = Math.floor(timeDifference / 1000);
  if (seconds < 60) {
    return "Publicado ahora mismo";
  }

  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  if (hours < 24) {
    return `Publicado hoy`;
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  if (days <= 7) {
    return `Publicado hace ${days} ${days === 1 ? "día" : "días"}`;
  }

  const weeks = Math.floor(days / 7);
  if (weeks <= 4) {
    return `Publicado hace ${weeks} ${weeks === 1 ? "semana" : "semanas"}`;
  }

  const months = Math.floor(days / 30);
  if (months <= 4) {
    return `Publicado hace ${months} ${months === 1 ? "mes" : "meses"}`;
  }

  return "Publicado hace un tiempo";
};


const Cards = ({ datos }) => {
  let location = useLocation()
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  return (
    <div className="mt-4">
      {datos.slice(startIndex, endIndex).map((dato) => (
        <div key={dato._id} className={dato.tipo === "noticia" ? "" : ""}>
          <div
            className={
              dato.tipo === "noticia"
                ? "card "
                : "card col-lg-10 col-sm-12 border-top-0 border-bottom-0 m-auto my-2"
            }

          >
            <img
              className={dato.tipo === "noticia" ? "card-img-top" : "d-none"}
              src={dato.tipo === "noticia" ? `${dato.img}` : ""}
              alt=""
            />
            <div className="card-body">
              <h3 className="card-title text-truncate text-capitalize fw-bold my-0">
                {dato.titulo}
              </h3>
              <p className="text-muted m-0 text-capitalize">
                <i
                  className={
                    dato.tipo === "noticia"
                      ? ""
                      : "fa-solid fa-location-dot me-1"
                  }
                ></i>
                {dato.ubicacion}
              </p>
              <p
                className={
                  dato.tipo === "noticia" ? "text-muted mb-0" : "d-none"
                }
              >
                {dato.descripcion}
              </p>
            </div>
            <div className="card-footer border-0 py-1 px-3 d-flex align-items-center justify-content-between gap-2">
              <Link
                to={
                  dato.tipo === "trabajo"
                    ? `/Trabajos/Trabajo/${dato._id}`
                    : `/Cursos/Curso/${dato._id}`
                }
                className={dato.tipo === "noticia" ? "btn btn-dark w-100" : "btn btn-dark"}
              >
                Ver mas
              </Link>
              <span className="text-secondary">{formatTimeSincePublication(dato.date)}</span>
            </div>
          </div>
        </div>
      ))}
      {/*Paginacion */}
      {
        location.pathname.includes("Trabajos") || location.pathname.includes("Cursos") ?
          <section className="d-flex justify-content-center align-items-center pt-3">
            <button
              className='btn btn-dark'
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Anterior
            </button>
            <div className="mx-3 fs-5 fw-bold text-white">
              {currentPage}
            </div>
            <button
              className='btn btn-dark'
              disabled={endIndex >= datos.length}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Siguiente
            </button>
          </section>
          : 
          ""
      }

    </div>
  );
};

export default Cards;
