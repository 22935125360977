import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import swal from "@sweetalert/with-react"
import { API_CURSOS, API_TRABAJOS, API_TEST_CURSOS, API_TEST_TRABAJOS, BACKOFFICE_URL_INSCRITOS, BACKOFFICE_URL_POSTULADOS, BACKOFFICE_URL_TEST_INSCRITOS, BACKOFFICE_URL_TEST_POSTULADOS } from "../../constants/constants";
import { uploadFile } from "../../config/firebase";
// import emailjs from '@emailjs/browser';

const Inscripcion = () => {
  const [file, setFile] = useState(null)
  const [idTrabajo, setIdTrabajo] = useState("")
  const [idCurso, setIdCurso] = useState("")
  let location = useLocation();
  let { id } = useParams()
  const [dato, setDato] = useState([])

  useEffect(() => {
    const fetching = async () => {
      if (location.pathname.includes("Trabajo")) {
        const api = `${API_TRABAJOS}/${id}`;
        const res = await fetch(api);
        const dato = await res.json();
        setDato(dato);
        setIdTrabajo(id)
      } else {
        const api = `${API_CURSOS}/${id}`;
        const res = await fetch(api);
        const dato = await res.json();
        setDato(dato);
        setIdCurso(id)
      }
    }
    fetching()
  }, [])
  const sendData = async (e) => {
    e.preventDefault();
    if (location.pathname.includes("Trabajo") && file.size > 2000000) {
      swal("¡Error!", "Se ha detectado que el archivo pesa mas de 2mb", "error")
      return
    }
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const data = {
      idCurso,
      nombre: e.target.nombre.value,
      email: e.target.email.value.trim().toLowerCase(),
      telefono: e.target.telefono.value,
      opcion: e.target.opcion.value
    }
    const dataPostulante = {
      trabajo: e.target.opcion.value,
      nombre: e.target.nombre.value,
      email: e.target.email.value.trim().toLowerCase(),
      telefono: e.target.telefono.value,
      cv: ""
    }
    const form = document.getElementById("form")
    if (location.pathname.includes("Trabajo")) {
      const cv = e.target.cargarCV.value
      if (cv == "") {
        swal("¡Error!", "Es obligatorio que suba el curriculum. Recuerde que solo se acepta formato PDF", "error")
        return
      }
    }
    if (data.nombre === "" || data.email === "" || data.telefono === "") {
      swal("¡Error!", "Todos los campos son obligatorios. Porfavor rellene todos", "error")
      return
    }

    if (data.nombre.length < 4) {
      swal("¡Error!", "El nombre debe de tener mas de 4 caracteres", "error")
      return
    }

    if (data.telefono.length < 6) {
      swal("¡Error!", "El telefono debe de tener mas de 6 caracteres", "error")
      return
    }

    if (!regexEmail.test(data.email)) {
      swal("¡Error!", "El email ingresado no es valido. Ingrese uno correcto ejemplo: email@gmail.com", "error")
      return
    }


    swal("Cargando... Espere porfavor")
    if (location.pathname.includes("Curso")) {
      // emailjs.sendForm("service_ffmjjim", "template_jvb5ivh", e.target, "-oSTqk-t5I-O7Bg7X")
      //   .then((result) => {
      //     swal("Formulario enviado", "Datos enviados con exito. Nos comunicaremos para mandarte mas informacion", "success")
      //     form.reset()
      //   })
      //   .catch((error) => {swal("¡Error!", "Ocurrio un error inesperado. Intenta de nuevo o mas tarde", "error")});
      try {


        // Realizar el POST a tu API
        const response = await fetch(BACKOFFICE_URL_INSCRITOS, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          // Si la respuesta no es exitosa, lanzar una excepción con el mensaje del servidor
          const errorData = await response.json();
          throw new Error(errorData.message);
        }

        // La respuesta fue exitosa, mostrar un mensaje de éxito
        swal("Éxito", "Formulario enviado correctamente", "success");
        e.target.nombre.value = ""
        e.target.email.value = ""
        e.target.telefono.value = " "
        e.target.opcion.value = ""
      } catch (error) {
        // Error al enviar el formulario, mostrar mensaje de error
        console.error("Error al enviar el formulario:", error.message);
        swal("Error", `${error.message}`, "error");
      }
    } else if (location.pathname.includes("Trabajo")) {
      try {
        const archivo = await uploadFile(file)
        if (archivo) {
          dataPostulante.cv = archivo
        }
        // Realizar el POST a tu API
        const response = await fetch(BACKOFFICE_URL_POSTULADOS, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataPostulante),
        });

        if (!response.ok) {
          // Si la respuesta no es exitosa, lanzar una excepción con el mensaje del servidor
          const errorData = await response.json();
          throw new Error(errorData.message);
        }

        // La respuesta fue exitosa, mostrar un mensaje de éxito
        swal("Éxito", "Formulario enviado correctamente", "success");

        e.target.nombre.value = ""
        e.target.email.value = ""
        e.target.telefono.value = ""

      } catch (error) {
        // Error al enviar el formulario, mostrar mensaje de error
        console.error("Error al enviar el formulario:", error.message);
        swal("Error", `${error.message}`, "error");
      }
    }
  };

  return (
    <div
      className="modal fade"
      id="exampleModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content rounded">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Rellena el formulario
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={sendData} id="form">
              <div className="">
                <label className="form-label">Nombre y Apellido</label>
                <input
                  className="form-control"
                  name="nombre"
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="mt-2">
                <label className="form-label">Email</label>
                <input
                  className="form-control"
                  name="email"
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="mt-2">
                <label className="form-label">Telefono</label>
                <input
                  className="form-control"
                  name="telefono"
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="mt-2">
                <label className="form-label">{location.pathname.includes("Trabajo") ? (<>Puesto</>) : (<>Curso</>)}</label>
                <select className="form-select" id="opcion" name="opcion" value={dato.titulo}>
                  <option value={dato.titulo}>{dato.titulo}</option>
                </select>
              </div>
              {location.pathname.includes("Curso") ? null : (
                <div className="mt-2">
                  <label className="form-label me-2" htmlFor="cargarCV">
                    <p className="m-0">Cargar CV <small className="text-danger">*limite 2mb - Solo se acepta formato ".pdf"</small></p>
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    name="cargarCV"
                    accept="application/pdf"
                    onChange={e => setFile(e.target.files[0])}
                  />
                </div>
              )}
              <div className="mt-2 d-flex gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-success">
                  {location.pathname.includes("Curso") ? (
                    <div className="">Quiero info</div>
                  ) : (
                    <div className="">Enviar postulacion</div>
                  )}
                </button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Inscripcion;
