import React from "react";
import Header from "../../components/header/Header";
import Cards from "../../components/cards/Cards";
import useFecthData from "../../hooks/useFecthData";
import Spinner from "../../components/spinner/Spinner";

const Cursos = () => {
  const { curso, isLoading } = useFecthData({ category: "curso" })
  return (
    <>
      <Header
        className="headerCursos"
        texto="Capacitate con nosotros y aumenta tus conocimientos"
      />
      <div className="">
        <div className="col-12">
          {
            isLoading ? <section className='d-flex justify-content-center my-4'><Spinner/></section> :
              <Cards datos={curso} />
          }
        </div>
      </div>
    </>
  );
};

export default Cursos;
