export const API_URL = "https://backend-rcyasociados-vercel-n4a1.vercel.app"

export const API_CURSOS = API_URL + "/api/cursos"
export const API_TRABAJOS = API_URL + "/api/trabajos"
export const API_NOTICIAS = API_URL + "/api/noticias"
export const API_CONTACTO_PERSONA = API_URL + "/api/contactoPersona"
export const API_CONTACTO_EMPRESA = API_URL + "/api/contactoEmpresa"
export const API_NEWSLETTER = API_URL + "/api/newsletter"
export const API_NEWSLETTER_END = API_URL + "/api/newsletter/unsubscribe"

export const API_TEST = "http://localhost:4001"

export const API_TEST_CURSOS = API_TEST + "/api/cursos"
export const API_TEST_TRABAJOS = API_TEST + "/api/trabajos"
export const API_TEST_NOTICIAS = API_TEST + "/api/noticias"
export const API_TEST_CONTACTO_PERSONA = API_TEST + "/api/contactoPersona"
export const API_TEST_CONTACTO_EMPRESA = API_TEST + "/api/contactoEmpresa"
export const API_TEST_NEWSLETTER = API_TEST + "/api/newsletter"
export const API_TEST_NEWSLETTER_END = API_TEST + "/api/newsletter/unsubscribe"




export const BACKOFFICE_URL_INSCRITOS = `${API_URL}/api/inscritos`
export const BACKOFFICE_URL_POSTULADOS = `${API_URL}/api/postulaciones`

export const BACKOFFICE_URL_TEST_INSCRITOS = "http://localhost:4001/api/inscritos"
export const BACKOFFICE_URL_TEST_POSTULADOS = "http://localhost:4001/api/postulaciones"