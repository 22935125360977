import contacto from "../../img/contacto.jpg"
import contratado from "../../img/contratado.jpg"
import empleados from "../../img/empleados.jpg"
import estudiante from "../../img/estudiante.jpg"
import logo from "../../img/logo.png"
import mujer from "../../img/mujer.jpg"
import contactanos from "../../img/contactanos.jpg"

export default{
    "contacto": contacto,
    "contratado": contratado,
    "empleados": empleados,
    "estudiante": estudiante,
    "logo": logo,
    "mujer": mujer,
    "contactanos": contactanos
}