import { initializeApp } from "firebase/app";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage"
import {v4} from "uuid"

const firebaseConfig = {
  apiKey: "AIzaSyBIgiGOeMv16ubve7tAn7M7ZduZfcgMzhI",
  authDomain: "rcyasociados-efc93.firebaseapp.com",
  projectId: "rcyasociados-efc93",
  storageBucket: "rcyasociados-efc93.appspot.com",
  messagingSenderId: "811172250440",
  appId: "1:811172250440:web:4269be47800cd3c804697d"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)

export async function uploadFile(file) {
    const storageRef = ref(storage, 'curriculums/' + v4())
    await uploadBytes(storageRef, file) 
    const url = await getDownloadURL(storageRef)
    return url
}